<template>
  <div class="page-container dashboard-page">
    <portal to="page-name">Dashboard</portal>
    <template v-if="hasAccess">
      <div class="dashboard-tiles">
        <div class="tile-item c-hand">
          <div class="tile-icon text-secondary">
            <fa-icon :icon="['fal', 'arrow-down']"></fa-icon>
          </div>
          <div class="tile-label">A receber vencidas</div>
          <div class="tile-value">{{ stats.revenueOverdueYear | currency }}</div>
        </div>
        <div class="tile-item c-hand">
          <div class="tile-icon text-secondary">
            <fa-icon :icon="['fal', 'arrow-down']"></fa-icon>
          </div>
          <div class="tile-label">A receber vencendo hoje</div>
          <div class="tile-value">{{ stats.revenueOverdueDay | currency }}</div>
        </div>
        <div class="tile-item c-hand">
          <div class="tile-icon text-secondary">
            <fa-icon :icon="['fal', 'arrow-down']"></fa-icon>
          </div>
          <div class="tile-label">A receber no mês</div>
          <div class="tile-value">{{ stats.revenueOverdueMonth | currency }}</div>
        </div>
      </div>
      <div class="dashboard-tiles">
        <div class="tile-item c-hand">
          <div class="tile-icon text-error">
            <fa-icon :icon="['fal', 'arrow-up']"></fa-icon>
          </div>
          <div class="tile-label">A pagar vencidas</div>
          <div class="tile-value">{{ stats.expenseOverdueYear | currency }}</div>
        </div>
        <div class="tile-item c-hand">
          <div class="tile-icon text-error">
            <fa-icon :icon="['fal', 'arrow-up']"></fa-icon>
          </div>
          <div class="tile-label">A pagar vencendo hoje</div>
          <div class="tile-value">{{ stats.expenseOverdueDay | currency }}</div>
        </div>
        <div class="tile-item c-hand">
          <div class="tile-icon text-error">
            <fa-icon :icon="['fal', 'arrow-up']"></fa-icon>
          </div>
          <div class="tile-label">A pagar no mês</div>
          <div class="tile-value">{{ stats.expenseOverdueMonth | currency }}</div>
        </div>
      </div>
      <div class="card chart-card">
        <div class="card-body">
          <div class="card-title">Evolução dos últimos 12 meses</div>
          <st-bar-chart
            :chart-data="charts.evolutions.data"
            :options="charts.evolutions.options"
            :height="250"/>
        </div>
      </div>
    </template>
    <forbidden v-else></forbidden>
  </div>
</template>

<script>
import moment from 'moment';
import currency from 'src/filters/currency';
import Forbidden from '@/components/auth/Forbidden.vue';
import { CREATE_FINANCIAL_TRANSACTION } from '@/data/actions/modules/financial';

export default {
  components: {
    Forbidden,
  },
  data() {
    return {
      loading: false,
      stats: {
        revenueOverdueYear: 0,
        expenseOverdueYear: 0,
        revenueOverdueMonth: 0,
        expenseOverdueMonth: 0,
        revenueOverdueDay: 0,
        expenseOverdueDay: 0,
      },
      charts: {
        evolutions: {
          data: {
            labels: [],
            datasets: [],
          },
          options: {
            maintainAspectRatio: false,
            legend: {
              display: false,
            },
            scales: {
              yAxes: [{
                ticks: {
                  beginAtZero: true,
                },
              }],
            },
          },
        },
      },
    };
  },
  mounted() {
    this.loadStats();
  },
  computed: {
    hasAccess() {
      return this.$can(CREATE_FINANCIAL_TRANSACTION);
    },
  },
  methods: {
    loadStats() {
      return this.$http.get('/financial-transactions/stats')
        .then(({ data: result }) => {
          this.stats.revenueOverdueYear = result.revenueOverdueYear;
          this.stats.expenseOverdueYear = result.expenseOverdueYear;
          this.stats.revenueOverdueMonth = result.revenueOverdueMonth;
          this.stats.expenseOverdueMonth = result.expenseOverdueMonth;
          this.stats.revenueOverdueDay = result.revenueOverdueDay;
          this.stats.expenseOverdueDay = result.expenseOverdueDay;

          this.charts.evolutions.data = {
            labels: result.evolutions
              .map(({ date }) => moment(date).format('MMM/YYYY')),
            datasets: [
              {
                label: 'Receitas',
                data: result.evolutions.map(({ revenue }) => revenue),
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1,
              },
              {
                label: 'Despesas',
                data: result.evolutions.map(({ expense }) => expense),
                backgroundColor: 'rgba(255, 99, 132, 0.2)',
                borderColor: 'rgba(255, 99, 132, 1)',
                borderWidth: 1,
              },
              {
                label: 'Lucro/Prejuízo',
                data: result.evolutions.map(({ profit }) => profit),
                backgroundColor: 'rgba(255, 206, 86, 0.2)',
                borderColor: 'rgba(255, 206, 86, 1)',
                borderWidth: 1,
              },
            ],
          };
          this.charts.evolutions.options = {
            tooltips: {
              callbacks: {
                label(tooltipItem, data) {
                  const label = data.datasets[tooltipItem.datasetIndex].label || '';
                  return `${label}: ${currency(tooltipItem.yLabel)}`;
                },
              },
            },
            maintainAspectRatio: false,
            legend: {
              display: false,
            },
            scales: {
              yAxes: [{
                ticks: {
                  beginAtZero: true,
                },
              }],
            },
          };
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss">
  @import "./src/assets/scss/_variables.scss";
  @import "./src/assets/scss/mixins";

  .dashboard-page {
    .dashboard-tiles {
      display: flex;
      padding: 0 0 $layout-spacing-lg 0;
      color: $gray-color-dark;
      .tile-item {
        background-color: $light-color;
        border: $border-width solid $border-color;
        border-radius: $border-radius;
        flex: 1;
        margin-left: $layout-spacing-lg;
        padding: 20px;
        position: relative;
        text-align: center;
        &:first-child {
          margin-left: 0;
        }
      }
      .tile-icon {
        margin-bottom: $layout-spacing;
        svg {
          display: block;
          height: 1.2rem;
          margin: 0 auto;
          width: auto;
        }
      }
      .tile-label {
        font-size: $font-size-sm;
        line-height: $font-size-sm;
        margin: $layout-spacing-sm 0 calc($layout-spacing-sm / 2) 0;
      }
      .tile-value {
        color: $secondary-color;
        font-size: $font-size-lg;
        font-weight: 600;
        line-height: $font-size;
        margin-top: $layout-spacing;
      }
    }
    .chart-card {
      background-color: $light-color;
      border: $border-width solid $border-color;
      border-radius: $border-radius;
      .card-title {
        font-size: $font-size-lg;
        text-align: center;
      }
    }

    .scroll-list-wrapper {
      flex-grow: 1;
      position: relative;
    }

    .scroll-list {
      bottom: 0;
      overflow-y: auto;
      top: 0;
      width: 100%;
      height: 256px;
      @include scroll-bar();
    }
  }
</style>
